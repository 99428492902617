import { Helmet } from "react-helmet"
import React from "react"
export default function Layout({ children }) {
  return (
    <div className="body-layout">
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="keywords"
          content="vývoj aplikace, vývoj systému, konzultace vývoje IT, vývoj mobilní aplikace"
        />
        <meta
          name="description"
          content="Poskytujeme konzultaci k vývoji informačního systému, vývoji mobilní aplikace nebo webové aplikace zdarma. Konzultace probíhá online, rezervujte si termín."
        />
        <title>iQuest: konzultace vývoje aplikace nebo systému</title>
        {/* TODO potreba pak nastavit a sprvnou adresu */}
        <link
          rel="canonical"
          href="https://iquest-konzultace-client.netlify.app/"
        />
      </Helmet>
      <div
        style={{
          margin: `0 auto`,
          width: "90%",
        }}
      >
        {children}
      </div>
    </div>
  )
}
